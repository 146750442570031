<template>
  <div class="e-container" ref="fullscreenElement">
    <div class="m-exam">
      <el-tag size="small" type="warning" effect="dark">{{ examTypeMap[exam?.type] }}</el-tag>
      <span class="m-exam-name">{{ exam?.name }}</span>
    </div>


    <el-container class="m-exam-wrapper">
      <div class="m-container stu-container">
        <div class="m-tag" @click="toggleFullScreen">考试信息</div>
        <div class="stu-exam-info">
          <div>
            <div>姓名：{{ user?.name }}</div>
            <div>身份证号：{{ user?.account }}</div>
          </div>
          <div>
            <div>考试题数：</div>
            <div>{{ qTypeMap[1] }}{{ examInfo.q1Count || '--' }}</div>
            <div>{{ qTypeMap[2] }}{{ examInfo.q2Count || '--' }}</div>
            <div>{{ qTypeMap[3] }}{{ examInfo.q3Count || '--' }}</div>
          </div>
          <div>
            <div>考试时间：{{ examInfo.time || '--' }}</div>
            <div>满分{{ examInfo.maxScore || '--' }}分，{{ examInfo.maxScore * 6 / 10 || '--' }}分及格</div>
          </div>
        </div>
      </div>

      <div class="m-container exam-container">
        <div class="m-tag">考试题目</div>
        <div class="exam-info">
          <div class="exam-content">{{ question.index + 1 }}、{{ question.context }}</div>
          <div class="exam-options">
            <div v-for="(item, i) in question.options" :key="'option:' + i">
              {{ item.optionChoise }}、{{ item.optionMessage }}
            </div>
          </div>
          <el-container class="exam-operate">
            <div>请选择：</div>
            <template v-if="question.type === 1">
              <el-radio-group v-model="question.answer" @input="submitType1Question(question)">
                <el-radio-button :label="item.optionChoise" v-for="(item, i) in question.options" :key="'operate:' + i">
                </el-radio-button>
              </el-radio-group>
            </template>
            <template v-if="question.type === 2">
              <el-checkbox-group v-model="question.answers" @input="submitType2Question(question)">
                <el-checkbox-button :label="item.optionChoise" v-for="(item, i) in question.options"
                  :key="'operate:' + i">
                </el-checkbox-button>
              </el-checkbox-group>
            </template>
            <template v-if="question.type === 3">
              <el-radio-group v-model="question.answer" @input="submitType3Question(question)">
                <el-radio-button label="T">正确</el-radio-button>
                <el-radio-button label="F">错误</el-radio-button>
              </el-radio-group>
            </template>
          </el-container>
        </div>
      </div>

      <div class="m-container question-container">
        <div class="m-tag">考试题号</div>
        <div class="inner">
          <div class="question-item" :class="getQuestionStateStyle(item, i)" v-for="(item, i) in questions"
            :key="'question:' + i" @click="changeQuestion(i)">
            {{ i + 1 }}
          </div>
        </div>

      </div>
    </el-container>

    <el-container class="m-exam-wrapper">
      <div class="m-container time-container">
        <div class="m-tag">剩余时间</div>
        <div class="exam-time">
          {{ beautifyExamLeftTime() }}
        </div>
      </div>

      <div class="m-container q-type-container">
        <div class="m-tag">试题提示信息</div>
        <template v-if="question.type === 1">
          <div>单选题，请选择你认为正确的答案</div>
        </template>
        <template v-if="question.type === 2">
          <div>多选题，请选择合理正确的答案</div>
        </template>
        <template v-if="question.type === 3">
          <div>判断题，请判断对错</div>
        </template>
      </div>

      <div class="m-container q-exam-container" style="margin-top: 20px;">
        <div class="buttons">
          <template v-if="isFirstQuestion">
            <el-button type="primary" plain size="medium" disabled key="btn-pre-1">上一题</el-button>
          </template>
          <template v-else>
            <el-button type="primary" plain size="medium" key="btn-pre-2" @click="preQuestion">上一题</el-button>
          </template>
          <template v-if="isLastQuestion">
            <el-button type="primary" plain size="medium" disabled key="btn-next-1">下一题</el-button>
          </template>
          <template v-else>
            <el-button type="primary" plain size="medium" key="btn-next-2" @click="nextQuestion">下一题</el-button>
          </template>
          <el-button type="success" plain size="medium" @click="submitExam">交卷</el-button>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'studentExam',
  data() {
    return {
      examTypeMap: {
        1: '正式考试',
        2: '模拟考试',
      },
      qTypeMap: {
        1: '单选题',
        2: '多选题',
        3: '判断题',
      },
      examId: null,
      exam: null,
      examStudent: null,
      examLeftSeconds: null,
      examDeadline: null,
      user: null,
      question: {
        index: 0,
        type: null,
        context: null,
        answer: null,
        answers: [],
        options: [],
      },
      examInfo: {
        q1Count: null,
        q2Count: null,
        q3Count: null,
        maxScore: null,
        time: null,
      },
      questions: [],
      answers: [],
    };
  },

  beforeMount() {
    try {
      const user = window.localStorage.getItem('user');
      const userInfo = JSON.parse(user);
      if (!userInfo || userInfo.type !== 2) {
        this.$router.go(-1);
        return;
      }
      this.user = userInfo;
    } catch (e) {
      this.$router.go(-1);
      return;
    }
    const { id } = this.$route.query;
    if (!id) {
      this.$router.go(-1);
      return;
    }
    this.examId = id;
  },
  async mounted() {
    const exam = await this.queryExam({ id: this.examId });
    if (!exam) {
      this.$router.go(-1);
      return;
    }

    const examStudent = await this.queryExamStudent({ examId: this.examId });
    if (!examStudent) {
      this.$router.go(-1);
      return;
    }

    const examDeadline = await this.queryExamDeadline({ id: exam.id });
    if (examDeadline.startLeftSeconds > 30) {
      this.$router.go(-1);
      return;
    }
    // this.fullscreenCheck();



    this.procExamLeftTimeInterval(examDeadline);

    const questions = await this.queryExamQuestionList({ id: exam.id });
    const examInfo = Object.assign({}, this.calExamTime(examDeadline), this.calQCount(questions), this.calMaxScore(questions));

    const answers = await this.queryUserExamAnswerStateList({ examId: exam.id });

    this.exam = exam;
    this.examStudent = examStudent;
    this.examDeadline = examDeadline;
    this.questions = questions;
    this.examInfo = examInfo;
    this.answers = answers;
    this.question = this.getQuestion(0);

  },
  computed: {
    isFirstQuestion() {
      return this.question.index <= 0;
    },
    isLastQuestion() {
      return this.question.index >= this.questions.length - 1;
    },
  },
  watch: {
    examLeftSeconds: async function (newVal) {
      if (newVal > -1) {
        return;
      }
      if (this.examStudent?.submit === 0) {
        this.examStudent.submit = 1;
        const submitResult = await this.Api.student.submitExam({ examId: this.exam.id });
        if (submitResult.success && submitResult.data) {
          this.$message({
            type: 'success',
            message: '已交卷',
          });
          this.$alert('考试时间已到，系统已您自动交卷', '提示', {
            confirmButtonText: '确定',
            showClose: false,
            type: 'warning',
          }).then(async () => {
            this.$router.push({
              path: '/student/score',
              query: {
                id: this.exam.id,
              },
            });
          });

        } else {
          this.examStudent.submit = 0;
          this.$message({
            type: 'error',
            message: '网络错误，请重试',
          });
        }

      }
    },
  },
  methods: {
    toggleFullScreen() {
      // alternative standard method
      if (!document.fullscreenElement &&
        !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {  // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },

    async queryExam(data) {
      const examResult = await this.Api.student.queryExam(data);
      return examResult.data;
    },
    async queryExamStudent(data) {
      const examResult = await this.Api.student.queryExamStudent(data);
      return examResult.data;
    },
    async queryExamDeadline(data) {
      const examDeadlineResult = await this.Api.student.queryExamDeadline(data);
      return examDeadlineResult.data;
    },
    async queryExamQuestionList(data) {
      const questionsResult = await this.Api.student.queryExamQuestionList(data);
      return questionsResult.data;
    },
    async queryUserExamAnswerStateList(data) {
      const answersResult = await this.Api.student.queryUserExamAnswerStateList(data);
      return answersResult.data;
    },
    procExamLeftTimeInterval(examDeadline) {
      this.examLeftSeconds = examDeadline.endLeftSeconds;
      setInterval(() => {
        this.examLeftSeconds--;
      }, 1000);
    },
    async autoSubmit() {
      const submitResult = await this.Api.student.submitExam({ examId: this.exam.id });
      if (submitResult.success && submitResult.data) {
        this.$message({
          type: 'success',
          message: '已交卷',
        });
        this.$router.push({
          path: '/student/score',
          query: {
            id: this.exam.id,
          },
        });
      } else {
        if (submitResult.code === '998') {
          this.$message({
            type: 'error',
            message: submitResult.message,
          });
          return;
        }
        this.$message({
          type: 'error',
          message: '网络错误，请重试',
        });
      }
    },
    beautifyExamLeftTime() {
      const examSeconds = this.examLeftSeconds;
      if (examSeconds < 0) {
        // 自动交卷
        return '00:00:00';
      }
      const hours = parseInt(examSeconds / 3600);
      const minutes = parseInt((examSeconds % 3600) / 60);
      const seconds = parseInt((examSeconds % 3600) % 60);
      const hourTime = hours > 0 ? `${hours}`.padStart(2, '0') : '00';
      const minuteTime = minutes > 0 ? `${minutes}`.padStart(2, '0') : '00';
      const secondTime = seconds > 0 ? `${seconds}`.padStart(2, '0') : '00';
      return [hourTime, minuteTime, secondTime].join(':');
    },
    calExamTime(examDeadline) {
      const examSeconds = examDeadline.endLeftSeconds - examDeadline.startLeftSeconds;
      const hours = parseInt(examSeconds / 3600);
      const minutes = parseInt((examSeconds % 3600) / 60);
      const hourTime = hours > 0 ? `${hours}小时` : '';
      const minuteTime = minutes > 0 ? `${minutes}分钟` : '';
      return {
        time: hourTime + minuteTime,
      };
    },
    calQCount(questions) {
      const q1Count = questions.filter((q) => q.type === 1).length + '题';
      const q2Count = questions.filter((q) => q.type === 2).length + '题';
      const q3Count = questions.filter((q) => q.type === 3).length + '题';
      return {
        q1Count,
        q2Count,
        q3Count,
      };
    },
    calMaxScore(questions) {
      const maxScore = questions.reduce(function (pre, cur) {
        return pre + cur.score
      }, 0);
      return {
        maxScore,
      }
    },
    getQuestionStateStyle(question, index) {
      if (this.question.index === index) {
        return 'question-active';
      }
      if (this.answers.filter((a) => a.questionId === question.questionId).length > 0) {
        return 'question-submitted';
      }
      return '';
    },
    preQuestion() {
      this.changeQuestion(this.question.index - 1);
    },
    nextQuestion() {
      this.changeQuestion(this.question.index + 1);
    },
    changeQuestion(index) {
      this.question = this.getQuestion(index);
    },
    getQuestion(index) {
      const q = this.questions[index].question;
      const a = this.answers.filter((item) => item.questionId === q.id).pop();
      const question = {
        index: index,
        answer: a?.answer,
        answers: a?.answer?.split('') ?? [],
        type: q.type,
        context: q.context,
        options: JSON.parse(q.options),
      };
      return question;
    },
    async submitQuestion(data) {
      const submitResult = await this.Api.student.submitQuestion(data);
      if (submitResult.success && submitResult.data) {
        this.answers = await this.queryUserExamAnswerStateList({ examId: this.exam.id });
        return;
      }
      if (submitResult.code === '998') {
        this.question = this.getQuestion(this.question.index);
        this.$message({
          type: 'error',
          message: '已交卷，无法修改答案',
        });
        return;
      }
      this.question.answer = null;
      this.question.answers = [];
      this.$message({
        type: 'error',
        message: '网络错误，请重新选择',
      });
    },
    submitType1Question(question) {
      const data = {
        examId: this.exam.id,
        questionId: this.questions[this.question.index].questionId,
        answer: question.answer,
      };
      this.submitQuestion(data);
    },
    submitType2Question(question) {
      const data = {
        examId: this.exam.id,
        questionId: this.questions[this.question.index].questionId,
        answer: question.answers.sort().join(''),
      };
      this.submitQuestion(data);
    },
    submitType3Question(question) {
      const data = {
        examId: this.exam.id,
        questionId: this.questions[this.question.index].questionId,
        answer: question.answer,
      };
      this.submitQuestion(data);
    },
    submitExam() {
      if (this.examStudent.submit === 1) {
        this.$router.push({
          path: '/student/score',
          query: {
            id: this.exam.id,
          },
        });
        return;
      }
      let submitTime = Number(this.exam.delaySubmitTime)
      let time = (new Date().getTime() - new Date(this.exam.startDttm).getTime()) - 1000 * 60 * submitTime
      console.log("🚀 ~ submitExam ~ time:", time)
      // if (time > 0) {
      //   this.$message({
      //     type: 'error',
      //     message: '提交试卷时间未到，请等待考试开始'+submitTime+'分钟后再交卷',
      //   });
      //   return
      // }

      const submitCount = this.answers.length;
      const unSubmitCount = this.questions.length - submitCount;
      const h = this.$createElement;
      this.$confirm('', {
        title: '提示',
        message: h('p', {}, [
          h('p', {}, [
            h('span', {}, '您已作答'),
            h('span', {}, `${submitCount}题，`),
            h('span', {}, '未作答'),
            h('span', { style: 'color: #f56c6c' }, `${unSubmitCount}题`),
          ]),
          h('p', {}, '您确定交卷？交卷后不可恢复'),
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const submitResult = await this.Api.student.submitExam({ examId: this.exam.id });
        if (submitResult.success && submitResult.data) {
          this.$message({
            type: 'success',
            message: '已交卷',
          });
          this.$router.push({
            path: '/student/score',
            query: {
              id: this.exam.id,
            },
          });
        } else {
          if (submitResult.code === '998') {
            this.$message({
              type: 'error',
              message: submitResult.message,
            });
            return;
          }
          this.$message({
            type: 'error',
            message: '网络错误，请重试',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.e-container {
  // width: 1360px;
  // margin: 0 auto;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  box-sizing: border-box;
  padding: 25px;
  border-radius: 12px;
  background-color: #fff;
  // display: flex;
  // flex-direction: column;
  row-gap: 25px;
  // align-items: center;
}

.m-exam {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  // height: 35px;
  padding: 10px 0;
  border-bottom: 1px solid #d3d4d6;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;

  .m-exam-name {
    font-size: 27px;
    font-weight: 500;
  }
}

.m-exam-wrapper {
  width: 100%;
  column-gap: 25px;
  justify-content: flex-start;
  margin-top: 20px;
}

.m-container {
  border: 1px solid #d3d4d6;
  border-radius: 12px;
  padding: 25px;
  position: relative;

  .m-tag {
    position: absolute;
    top: -15px;
    left: 20px;
    background: #fff;
    padding: 5px;
    font-weight: 600;
  }
}

.stu-container {
  width: 280px;
  height: 420px;

  .stu-exam-info {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    height: 100%;
    font-size: 17px;
    line-height: 27px;
  }
}

.exam-container {
  // width: 700px;
  flex: 1;
  height: 420px;

  .exam-info {
    height: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 18px;

    .exam-content {
      font-size: 21px;
      font-weight: 500;
    }

    .exam-options {
      margin-left: 25px;
      font-size: 19px;
      font-weight: 500;
      line-height: 27px;
    }

    .exam-operate {
      position: absolute;
      bottom: 0;
      right: 0;
      align-items: center;
      column-gap: 5px;
    }
  }
}

.question-container {
  width: 340px;
  height: 420px;


  .inner {
    height: calc(100% - 20px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 8px 4px;

    .question-item {
      width: 22px;
      height: 16px;
      padding: 6px 3px;
      background-color: #f4f4f5;
      border: 1px solid #d3d4d6;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      font-size: 13px;
    }

    .question-active {
      border-color: #e6a23c;
      background-color: #e6a23c;
      color: #fff;
    }

    .question-submitted {
      border-color: #409eff;
      background-color: #409eff;
      color: #fff;
    }

    .question-wrong {
      border-color: #f56c6c;
      background-color: #f56c6c;
      color: #fff;
    }

    .question-correct {
      border-color: #67c23a;
      background-color: #67c23a;
      color: #fff;
    }
  }




}

.time-container {
  width: 280px;
  height: 35px;

  .exam-time {
    font-size: 27px;
    color: #f56c6c;
    display: flex;
    align-items: center;
  }
}

.q-type-container {
  width: 240px;
  height: 35px;
  display: flex;
  align-items: center;
}

.q-exam-container {
  width: 430px;
  border: none;
  padding: 0;

  .buttons {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 25px;
  }
}
</style>
